<template >
  <CSidebar
    v-if="!isMobile"
    id="mysidebar"
    fixed
    :minimize="minimize"
    :show.sync="show"
  >
    <div class="c-sidebar-brand" @click="goTo()">
      <a rel="noopener">
        <span class="side-bar-brand-logo-full"> </span>
        <span class="side-bar-brand-logo-minimized"> </span>
      </a>
      <CBadge color="success">{{ version }}</CBadge>
    </div>
    <span class="brand-logo-mob c-header-brand mx-auto d-lg-none"> </span>

    <TheHeaderDropdownAccnt
      v-if="minimize && isNeedLogo"
      style="list-style: none"
      class="c-avatar-img square"
    />

    <div v-if="showUserInfo">
      <cUserInfo></cUserInfo>
    </div>
    <CRenderFunction flat :content-to-render="nav" />
    <a href="https://ascent24.io" target="_blank" class="poweredbytag"
      >Powered by Ascent24.io</a
    >
    <CSidebarMinimizer
      v-if="!isNeedLogo"
      class="d-md-down-none"
      @click.native="minimize = !minimize"
    />
  </CSidebar>

  <aSideMenu v-else-if="isMobile" :nav="nav" :show="show" :minimize="minimize">
  </aSideMenu>
</template>
<script>
import TheHeaderDropdownAccnt from "./TheHeaderDropdownAccnt";
import cUserInfo from "./../components/userinfo/cUserInfo";
import base from "./../config/base";
import { mapActions, mapGetters } from "vuex";
import { PATHNAME } from "../util/constants";
import apiGetMenus from "./../services/apiGetMenus";
import Logger from "./../services/logger";
import SERVICE_URL, { FILE_NAME, LOG_TYPE } from "./../config/API_DATA";
import { VALIDATE_SUCCESS_RES, getErrorMessage } from "./../util/apiValidate";
import {
  handleJWT,
  getErrorAlertValue,
  addClassToContainer,
} from "./../util/util";
import { navigate, navigateReplace } from "./../router/index";
import apiLogOut from "./../services/apiLogOut";
import store from "../store";
import BaseConfiguration from "./../config/base";
import { clearStore } from "./../store/clearStore";
import { removeB2BMFfromSession } from "./../applocalstorage/localStorage";
if (BaseConfiguration.isMobile) {
  // var aSideMenu = require('./../mobile/components/aSideMenu').default;
  // var AppPageControl = require('./../mobile/containers/aPageControl').default;
}
export default {
  name: "TheSidebar",
  components: {
    cUserInfo,

    TheHeaderDropdownAccnt,
  },
  data() {
    return {
      isMobile: BaseConfiguration.isMobile,
      lsNavMenus: [],
      b2b_registration_aggregate: {
        aggregate: { count: 0 },
      },
      version: "v" + base.version + " " + (base.isDebug ? "dev" : ""),
      minimize: false,
      isNeedLogo: false,
      nav: [],
      show: "responsive",
      isShowAlert: false,
      alertValue: null,
      //start
      showUserInfo: false,
      language: [
        {
          langcode: null,
          en_name: null,
          native_name: null,
        },
      ],
      languageListURL: SERVICE_URL.LANGUAGE_LIST,
      itemsCount: 42,
      isShowLoginInfo: false,
      userLogoDet: {
        logoDet: null,
      },
      //end
    };
  },
  computed: {
    ...mapGetters("sMenus", {
      GET_ALL_MENUS_RES: "GET_ALL_MENUS_RES",
    }),
    ...mapGetters("sCurrencyLs", {
      GET_ORG_CURRENCY_FORMAT_RES: "GET_ORG_CURRENCY_FORMAT_RES",
    }),
    ...mapGetters("sSysLogin", {
      ADMIN_SIGNIN_RES: "GET_ADMIN_SIGNIN_RES",
    }),
    getUserName: function () {
      let retValue;
      try {
        if (
          this.userLogoDet.logoDet &&
          this.userLogoDet.logoDet.firstname &&
          this.userLogoDet.logoDet.lastname
        ) {
          retValue =
            this.userLogoDet.logoDet.firstname +
            " " +
            this.userLogoDet.logoDet.lastname;
        } else if (
          this.userLogoDet.logoDet &&
          this.userLogoDet.logoDet.firstname
        ) {
          retValue = this.userLogoDet.logoDet.firstname;
        } else {
          retValue = "";
        }
      } catch (err) {
        let body = Logger.buildRequest(
          FILE_NAME.SIDE_BAR,
          "error in getting user name",
          err.toString(),
          "getUserName()",
          LOG_TYPE.ERROR
        );
        Logger.getResponse(body);
      }
      return retValue;
    },

    ...mapGetters("sSysLogin", {
      GET_ADMIN_SIGNIN_REQ: "GET_ADMIN_SIGNIN_REQ",
      GET_ADMIN_SIGNIN_RES: "GET_ADMIN_SIGNIN_RES",
    }),
  },

  methods: {
    ...mapActions("sMenus", {
      CLEAR_ALL_MENUS_STORE: "CLEAR_ALL_MENUS_STORE",
      SET_ALL_MENUS_REQ: "SET_ALL_MENUS_REQ",
      SET_ALL_MENUS_RES: "SET_ALL_MENUS_RES",
    }),
    //start
    ...mapActions("sSysLogin", {
      CLEAR_SYS_LOGIN: "CLEAR_SYS_LOGIN",
    }),
    goTo() {
      navigateReplace(PATHNAME.DASHBOARD);
    },
    showAlert(val) {
      this.alertValue = val;
    },

    toggleShowLoginInfo() {
      try {
        if (BaseConfiguration.isDebug) console.log("toggleShowLoginInfo");
        this.isShowLoginInfo = !this.isShowLoginInfo;
      } catch (err) {
        let body = Logger.buildRequest(
          FILE_NAME.THE_HEADER_DROPDOWN_ACCNT,
          "error in toggleShowLoginInfo",
          err.toString(),
          "toggleShowLoginInfo",
          LOG_TYPE.ERROR
        );
        Logger.getResponse(body);
      }
    },
    onLogout() {
      try {
        removeB2BMFfromSession();

        clearStore();
        apiLogOut.getLogout();
        if (store.getters["sSysLogin/GET_ADMIN_SIGNIN_RES"]) {
          this.CLEAR_SYS_LOGIN();
          navigate(PATHNAME.SYSTEM_LOGIN);
        } else {
          this.CLEAR_SYS_LOGIN();
          navigate(PATHNAME.SYSTEM_LOGIN);
        }
      } catch (err) {
        let body = Logger.buildRequest(
          FILE_NAME.THE_HEADER_DROPDOWN_ACCNT,
          "error in onLogout",
          err.toString(),
          "onLogout",
          LOG_TYPE.ERROR
        );
        Logger.getResponse(body);
      }
    },
    //end

    async getSideMenus() {
      let returnValue;
      try {
        let allMenusReq = apiGetMenus.buildRequest();

        if (allMenusReq != null) {
          this.SET_ALL_MENUS_REQ(allMenusReq);

          let allMenusRes = await apiGetMenus.getResponse(allMenusRes);
          if (VALIDATE_SUCCESS_RES(allMenusRes)) {
            returnValue = allMenusRes.data.data;
            if (BaseConfiguration.isMobile) {
              returnValue = this.$AppPageControl.appSideBarMenu(returnValue);
            }

            this.SET_ALL_MENUS_RES(returnValue);
          } else {
            let error = getErrorMessage(allMenusRes);

            let isJwt = handleJWT(error);
            if (!isJwt) {
              this.alertValue = getErrorAlertValue(error);
            } else {
              returnValue = [];
            }
          }
        } else {
          throw "invalid request";
        }
      } catch (err) {
        let body = Logger.buildRequest(
          FILE_NAME.SIDE_BAR,
          "error in getiing SideMenus",
          err.toString(),
          "getSideMenus",
          LOG_TYPE.ERROR
        );
        Logger.getResponse(body);
      }
      return returnValue;
    },

    sortSubMenus(pMenuList) {
      let returnValue;
      try {
        if (pMenuList && pMenuList.length > 0) {
          pMenuList.forEach((menu) => {
            if (menu.child && menu.child.length > 0) {
              menu.child.sort(function (subMenu1, subMenu2) {
                if (
                  Number(subMenu1.displayorder) > Number(subMenu2.displayorder)
                ) {
                  return 0;
                } else {
                  return -1;
                }
              });
            }
          });

          returnValue = pMenuList;
        } else {
          returnValue = [];
        }
      } catch (err) {
        let body = Logger.buildRequest(
          FILE_NAME.SIDE_BAR,
          "error in   sorting Sub Menus",
          err.toString(),
          "sortSubMenus",
          LOG_TYPE.ERROR
        );
        Logger.getResponse(body);
      }
      return returnValue;
    },

    sortMenus(pMenuList) {
      let returnValue;
      try {
        if (pMenuList && pMenuList.length > 0) {
          returnValue = pMenuList.sort(function (menu1, menu2) {
            if (parseInt(menu1.displayorder) > parseInt(menu2.displayorder)) {
              return 0;
            } else {
              return -1;
            }
          });
        } else {
          returnValue = [];
        }
      } catch (err) {
        let body = Logger.buildRequest(
          FILE_NAME.SIDE_BAR,
          "error in   sorting Menus",
          err.toString(),
          "sortMenus",
          LOG_TYPE.ERROR
        );
        Logger.getResponse(body);
      }
      return returnValue;
    },

    filterMenus(pMenuList) {
      let returnValue;
      let lsFilteredMenus = [];
      try {
        if (pMenuList && pMenuList.length > 0) {
          pMenuList.forEach((menu) => {
            if (menu && menu.child.length > 0) {
              let subMenuList = [];
              menu.child.forEach((submenu) => {
                subMenuList.push({
                  name: submenu.menuname,
                  isOpen: false,
                  to: submenu.routing ? submenu.routing : PATHNAME.DASHBOARD,
                  icon: submenu.icon,
                });
              });
              lsFilteredMenus.push({
                _name: "CSidebarNavDropdown",
                name: menu.menuname,
                icon: menu.icon,
                isOpen: false,
                to: menu.routing ? menu.routing : PATHNAME.DASHBOARD,
                items: subMenuList,
              });
            } else {
              lsFilteredMenus.push({
                _name: "CSidebarNavItem",
                name: menu.menuname,
                isOpen: false,
                icon: menu.icon,
                to: menu.routing ? menu.routing : PATHNAME.DASHBOARD,
              });
            }
          });

          returnValue = lsFilteredMenus;
        }
      } catch (err) {
        let body = Logger.buildRequest(
          FILE_NAME.SIDE_BAR,
          "error in Filter Menus",
          err.toString(),
          "filterMenus()",
          LOG_TYPE.ERROR
        );
        Logger.getResponse(body);
      }
      return returnValue;
    },

    // eslint-disable-next-line no-unused-vars
    toAddBadge(pLsMenus, pMenucountObj) {
      let retValue;
      try {
        if (pLsMenus && pLsMenus.length) {
          for (let i = 0; i < pLsMenus.length; i++) {
            if (pLsMenus[i] && pLsMenus[i].items && pLsMenus[i].items.length) {
              for (let j = 0; j < pLsMenus[i].items.length; j++) {
                break;
              }
            }
          }
        }
        retValue = pLsMenus;
      } catch (err) {
        let body = Logger.buildRequest(
          FILE_NAME.SIDE_BAR,
          "error in toAddBadge",
          err.toString(),
          "toAddBadge",
          LOG_TYPE.ERROR
        );
        Logger.getResponse(body);
      }
      return retValue;
    },
  },

  async created() {
    try {
      let lsMenus;
      //start
      let logoDetail = null;
      if (this.userLogoDetail) logoDetail = this.userLogoDetail;
      //logoDetail.roles.push('hello')
      if (logoDetail) this.userLogoDet.logoDet = logoDetail;
      //end

      if (this.GET_ALL_MENUS_RES) {
        lsMenus = this.GET_ALL_MENUS_RES;
      } else {
        lsMenus = await this.getSideMenus();
      }

      ///from temp
      // lsMenus = JSON.parse(JSON.stringify(menu));
      lsMenus = this.sortMenus(lsMenus);
      lsMenus = this.sortSubMenus(lsMenus);

      lsMenus = this.filterMenus(lsMenus);
      lsMenus = this.toAddBadge(lsMenus);
      this.lsNavMenus = lsMenus;
      this.nav.push({
        _name: "CSidebarNav",
        _children: lsMenus,
      });
    } catch (err) {
      let body = Logger.buildRequest(
        FILE_NAME.SIDE_BAR,
        "error in Created",
        err.toString(),
        "Created()",
        LOG_TYPE.ERROR
      );
      Logger.getResponse(body);
    }
    if (BaseConfiguration.isDebug) console.log("created");
  },
  watch: {
    // whenever sidebar minimied changes, this function will run
    // eslint-disable-next-line no-unused-vars
    minimize: function (newMinimize, oldMinimize) {
      if (newMinimize) {
        addClassToContainer(false);
      } else {
        addClassToContainer(true);
      }
    },
    "b2b_registration_aggregate.aggregate.count": function (
      newCount,
      // eslint-disable-next-line no-unused-vars
      oldCount
    ) {
      if (this.lsNavMenus.length > 0) {
        let lsMenus = this.toAddBadge(this.lsNavMenus, newCount);
        /*this.lsNavMenus[1].items[0].badge = {zz
                          color: "primary",
                          class:newCount?'':'zero',
                          text: newCount
                        };        */
        this.nav.push({
          _name: "CSidebarNav",
          _children: lsMenus,
        });
      }
    },
  },
  mounted() {
    this.$root.$on("toggle-sidebar", (isClose) => {
      const sidebarOpened = this.show === true || this.show === "responsive";
      this.show = sidebarOpened ? false : "responsive";
      if (isClose) {
        // console.error('isClo')
        this.show = false;
      } else {
        this.show = true;
      }

      // console.log(this.show);
    });
    this.$root.$on("toggle-sidebar-mobile", () => {
      const sidebarClosed = this.show === "responsive" || this.show === false;
      this.show = sidebarClosed ? true : "responsive";
    });
    this.$root.$on("toggle-sidebar-minimize", (isMaximize) => {
      if (isMaximize) {
        this.minimize = false;
        this.isNeedLogo = false;
      } else {
        this.minimize = true;
        this.isNeedLogo = true;
      }
    });
  },
};
</script>
