<template>
  <div class="c-app">
    <TheSidebar  />
    <div id="cwrapper" class="c-wrapper">
      
      <TheHeader />
      <div class="c-body">
        <main class="c-main">
          <CContainer fluid>
              <router-view></router-view>
          </CContainer>
        </main>
      </div>
      <!-- <TheFooter/> -->
    </div>
  </div>
</template>

<script>
import TheSidebar from './TheSidebar'
import TheHeader from './TheHeader'

//import { create } from 'domain'




export default {
  name: 'TheContainer',
  components: {
    TheSidebar,
    TheHeader,
    
  },
   data: () => ({
  }),
  methods:{
  },
  mounted() {
        },
  
}
</script>

<style>
.desc{
  padding-left: 10px
}
.removewrap{
  white-space: nowrap
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
.removewrap{
  white-space: nowrap;
}
</style>
