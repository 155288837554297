<template>
  <div v-if="!isMobile" class="">
    
    <CHeader
      :fixed="isFixedHeader"
      :with-subheader="false"
      light
      class="flex-flow-unset"
    >
    
      <div class="cus-header-main">
        <div class="search-flight-wrapper w-100">
         
          <div class="wrapper">
            <div class="logo" @click="navToDashBoard()">
              <img
                class=""
                src="@imgPath/img/svg/logo/brand-white.svg"
                width="auto"
                height="43"
                alt="CoreUI Logo"
              />
            </div>
             <div >
               {{selProj.name}}
                      <!-- <cDropDown
                        :key="'org'"
                        :url="org"
                        :isAllowEmpty="false"
                        :ctrlCode="'value'"
                        :ctrlName="'name'"
                        :isShowCode="false"
                        :isMultiple="false"
                        :isSearchable="true"
                        v-on:input="onStatusChange"
                        :selValue="selProj"
                        class="hide-invalid-feedback"
                      /> -->
                    </div>
            <div class="d-flex h-search-wrap">
              <TheHeaderDropdownAccnt style="list-style: none" />
            </div>
          </div>
        </div>
      </div>

      <CToggler
        in-header
        class="ml-3 d-lg-none"
        v-c-emit-root-event:toggle-sidebar-mobile
      />
      <span class="brand-logo-center c-header-brand mx-auto d-lg-none"> </span>
      <CHeaderNav class="d-md-down-none mr-auto"> </CHeaderNav>
    </CHeader>
  </div>
  <aHeader v-else-if="isMobile" :isFixedHeader="isFixedHeader"> </aHeader>
</template>

<script>
import TheHeaderDropdownAccnt from "./TheHeaderDropdownAccnt";
import { mapGetters } from "vuex";
import SERVICE_URL from "../config/API_DATA";
import { navigate } from "../router/index";
import { PATHNAME } from "../util/constants.js";
import { LOG_TYPE, API_VALUES, } from "../config/API_DATA";
import BaseConfiguration from "../config/base";
import Logger from "../services/logger";
import { mapActions } from "vuex";
import cDropDown from "../components/form/cDropDown";
if (BaseConfiguration.isMobile) {
  // var aHeader = require('./../mobile/components/aHeader').default;
  // import("./../mobile/components/aHeader");
}
export default {
  name: "TheHeader",
  components: {
    TheHeaderDropdownAccnt,
    cDropDown,
  },

  data() {
    return {
      selProj: null,
      org: SERVICE_URL.SELECT_ORG,
      isMobile: BaseConfiguration.isMobile,
      isFixedHeader: true,
      languageListURL: SERVICE_URL.LANGUAGE_LIST,
      language: [
        {
          langcode: null,
          en_name: null,
          native_name: null,
        },
      ],
      menuList: [],
      selMenu: null,
    };
  },
  mounted() {
    this.$root.$on("toggle-fixed-header", (isClose) => {
      if (isClose) {
        this.isFixedHeader = false;
      } else {
        this.isFixedHeader = true;
      }
    });
  },
  methods: {
    // ...mapActions("sSimulateJwt", {SIMULATE_JWT: "SIMULATE_JWT"}),
...mapActions("sSysLogin", ["SET_ORGID"]),
...mapActions("sSysLogin", ["SET_SEL_PROJ"]),
        onStatusChange(pStatus) {
      try {
        if (BaseConfiguration.isDebug) console.log("onStatusChange");
        if (pStatus && pStatus.value) {
          this.selProj = pStatus
         this.SET_SEL_PROJ(pStatus)
          this.SET_ORGID(pStatus.value);
          API_VALUES.ORG_ID = pStatus.value;
        } else {
          throw new Error("pStatus not found");
        }

        // this.resetUserRole();
      } catch (err) {
        let body = Logger.buildRequest(
          FILE_NAME.MANAGE_USERS,
          "error in on Status Change",
          err.toString(),
          "onStatusChange",
          LOG_TYPE.ERROR
        );
        Logger.getResponse(body);
      }
    },
    navToDashBoard() {
      if (this.$route.path != PATHNAME.DASHBOARD) {
        navigate(PATHNAME.DASHBOARD);
      }
    },
  
    navToMyBookingPage() {
      if (this.$route.path != PATHNAME.MY_BOOKING) {
        navigate(PATHNAME.MY_BOOKING);
      }
    },

    navToTicketingPage() {
      if (this.$route.path != PATHNAME.TICKETING_QUEUE) {
        navigate(PATHNAME.TICKETING_QUEUE);
      }
    },

    onChangeMenu(pMenu) {
      try {
        if (BaseConfiguration.isDebug) console.log("onChangeMenu");
        //let retValue;
        this.selMenu = pMenu;
        let routing = null;
        if (pMenu && pMenu.child && pMenu.child.length > 0)
          routing = pMenu.child[0].routing;
        else if (pMenu && pMenu.routing) routing = pMenu.routing;
        if (routing && routing != this.$route.path) {
          navigate(routing);
        }
      } catch (err) {
        let body = Logger.buildRequest(
          "TheHeader",
          "error in onChangeMenu",
          err.toString(),
          "onChangeMenu",
          LOG_TYPE.ERROR
        );
        Logger.getResponse(body);
      }
      //return retValue;
    },
  },
  computed: {
    ...mapGetters("sSysLogin", { SystSignInReq: "GET_ADMIN_SIGNIN_REQ",
    GET_SEL_PROJ: "GET_SEL_PROJ" }),
    ...mapGetters("sMenus", { menuStoreRes: "GET_ALL_MENUS_RES" }),
  },

  watch: {
    menuStoreRes(val) {
      if (val) {
        this.menuList = val;
      }
    },
  },
  created() {
    if (this.menuStoreRes) {
      this.menuList = this.menuStoreRes;
    }
    if(this.GET_SEL_PROJ){
      this.selProj = this.GET_SEL_PROJ
    }
  },
};
</script>

<style>
.round {
  border-radius: 50%;
}
</style>
